import { useSubscription } from '@apollo/client';
import { Chart } from 'chart.js';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  BeersConsumedEntry,
  BeersConsumedEvery10min,
  BeersConsumedEvery10minResponse,
} from '../lib/graphql';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

function getGradient(ctx: CanvasRenderingContext2D) {
  var gradient = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(0, 'rgba(255, 97, 98, 0.2)');
  gradient.addColorStop(0.2, 'rgba(255, 97, 98, 0.1)');
  gradient.addColorStop(0.6, 'rgba(255, 97, 98, 0  )');

  return gradient;
}

const timeStampToTimeString = (timeStamp: string): string => {
  let date = new Date(timeStamp);
  let mins = ('0' + date.getMinutes()).slice(-2);
  let hours = ('0' + date.getHours()).slice(-2);
  return hours + ':' + mins;
};

const cleanData = (dataPoint: BeersConsumedEntry) => ({
  ...dataPoint,
  start_time: timeStampToTimeString(dataPoint.start_time),
});

const accumulateBeers = () => {
  let accumulatedCounter = 0;
  return (dataPoint: BeersConsumedEntry) => {
    accumulatedCounter += dataPoint.beers_consumed;
    return { ...dataPoint, beers_consumed: accumulatedCounter };
  };
};

export default function BeerStats() {
  const { loading, error, data } =
    useSubscription<BeersConsumedEvery10minResponse>(BeersConsumedEvery10min);
  const chartJs = useRef<Chart | null>(null);

  const totalBeers = useMemo<BeersConsumedEntry[]>(
    () => data?.beers_consumed_every_10min.map(cleanData).map(accumulateBeers()) || [],
    [data]
  );
  const labels = useMemo(() => totalBeers.map((d) => d.start_time), [totalBeers]);
  const beers_consumed = useMemo(() => totalBeers.map((d) => d.beers_consumed), [totalBeers]);

  useEffect(() => {
    if (chartJs.current && data?.beers_consumed_every_10min) {
      chartJs.current.data.labels = labels;
      chartJs.current.data.datasets[0].data = beers_consumed;
    }
  }, [beers_consumed, data, labels]);

  if (loading) return <p className="text-center">Laster..</p>;
  if (error) return <p className="text-center">`Error! ${error}`</p>;

  return (
    <div className="relative w-screen max-w-full overflow-visible">
      <Line
        ref={chartJs}
        options={{
          responsive: true,
          layout: {
            padding: { top: 30, right: 20 },
          },
          scales: {
            yAxes: { ticks: { color: '#a4a2ac' }, display: false },
            xAxes: { ticks: { color: '#a4a2ac' } },
          },
          elements: {
            point: {
              // radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
              labels: {
                color: 'orange',
              },
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              data: beers_consumed,
              fill: true,
              // @ts-ignore
              backgroundColor: (context: { chart: Chart }) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return null;
                }
                return getGradient(ctx);
              },
              borderColor: 'rgba(255, 97, 92, 1)',
              // tension: 0.1,
              cubicInterpolationMode: 'monotone',
              datalabels: {
                align: 'end',
                anchor: 'end',
                // backgroundColor: 'rgba(255, 97, 92, 1)',
                color: 'rgba(255, 97, 92, 1)',
                borderRadius: 5,
                font: {
                  weight: 'bold',
                },
                formatter: function (value, context) {
                  if (context.dataIndex === context.dataset.data.length - 1) {
                    return value.y;
                  }
                  return '';
                },
              },
            },
          ],
        }}
      />
    </div>
  );
}
