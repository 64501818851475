import { User } from './types';

class LocalStorageHandler<T> {
  localStorageKey: string;

  constructor(localStorageKey: string) {
    this.localStorageKey = localStorageKey;
  }

  setValue(value: T) {
    if (typeof window === 'undefined') return;
    const stringValue = JSON.stringify(value);
    localStorage.setItem(this.localStorageKey, stringValue);
  }

  getValue(): T | null {
    if (typeof window === 'undefined') return null;
    const item = localStorage.getItem(this.localStorageKey);
    if (item) {
      try {
        const jsonItem = JSON.parse(item);
        return <T>jsonItem;
      } catch (e) {
        console.error('Could not JSON.parse item from localStorage');
      }
    }
    return null;
  }

  clearValue() {
    if (typeof window === 'undefined') return;
    localStorage.removeItem(this.localStorageKey);
  }
}

export const localStorageHandlers = {
  user: new LocalStorageHandler<User>('aco-fylla-user'),
};
