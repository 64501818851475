import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

function getClock() {
  const today = new Date();
  let h = today.getHours();
  let m = today.getMinutes();
  let s = today.getSeconds();
  m = checkTime(m);
  s = checkTime(s);
  return h + ':' + m + ':' + s;
}

function checkTime(i: any) {
  if (i < 10) {
    i = '0' + i;
  } // add zero in front of numbers < 10
  return i;
}

export function useClock() {
  const [clock, setClock] = useState(getClock);

  useEffect(() => {
    const intervalId = setInterval(() => setClock(getClock()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  return clock;
}

export function useDayjsClock() {
  const [clock, setClock] = useState(dayjs());

  useEffect(() => {
    const intervalId = setInterval(() => setClock(dayjs()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  return clock;
}
