import { useMutation } from '@apollo/react-hooks';
import Button from './Button';
import { SignupMutation } from '../lib/graphql';
import { useRecoilState } from 'recoil';
import { userState } from '../lib/recoil/user';
import { localStorageHandlers } from '../lib/recoil/localstorage';
import { User } from '../lib/recoil/types';
import ImageUpload from './ImageUpload';
import React, { useState } from 'react';
import { generateRandomFileName } from '../lib/utils/imageUpload';
import { faCamera, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Signup() {
  const [signup, { loading }] = useMutation(SignupMutation);
  const [_, setUser] = useRecoilState(userState);
  const [step, setStep] = useState(0);

  const [name, setName] = useState('');
  const [role, setRole] = useState('Selskapsledelse');

  const signupAndLoginUser = async (image_url: string) => {
    const { data, errors } = await signup({ variables: { name, role, image_url } });
    if (errors) {
      throw Error(errors.toString());
    }
    const newUser: User = data.insert_users.returning[0];
    localStorageHandlers.user.setValue(newUser);
    setUser(newUser);
  };

  const welcomeScreen = (
    <div>
      <img src="acoWhiteMindreWack.png" />
      <h1>Pinne for ACO 2021</h1>
      <h2>Velkommen!</h2>
      <p>
        I denne appen kan man laste opp bilder fra turen, og legge inn hver gang man fullfører en
        drank!
      </p>
      <Button onclick={() => setStep(1)}>Kom i gang!</Button>
    </div>
  );

  const nameSelector = (
    <div className="flex flex-col justify-center items-center">
      <h1>Jeg heter</h1>
      <input
        className="block my-2 p-2 bg-transparent border-b text-center"
        type="text"
        placeholder="Fyll inn navn"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <h4 className="mt-4">og jobber med</h4>
      <select
        className="block my-2 p-2 bg-transparent border-b text-center"
        value={role}
        onChange={(e) => setRole(e.target.value)}>
        <option>Selskapsledelse</option>
        <option>Partner</option>
        <option>Finans/Accounting</option>
        <option>HR (Mette)</option>
        <option>Marketing</option>
        <option>Tech</option>
        <option>Trainee/Intern</option>
      </select>
      <Button onclick={() => setStep(2)}>Neste</Button>
    </div>
  );

  const imageUpload = (
    <div>
      <h1>Halla {name}!</h1>
      <p>Ta et bilde av trynet ditt, så du ser tøff ut på leaderbords utover kvelden</p>
      {loading ? (
        'Oppretter bruker'
      ) : (
        <div className="flex justify-center items-center flex-col">
          <label className="inline-flex items-center justify-center bg-primary w-16 h-16 rounded-full ">
            <FontAwesomeIcon icon={faCamera} size="lg" />
            <ImageUpload
              fileName={'profile/' + generateRandomFileName()}
              onImageUpload={signupAndLoginUser}
            />
          </label>
          <FontAwesomeIcon className="mt-6 block bounce" icon={faChevronUp} size="lg" />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex items-center justify-center flex-col text-center p-4">
      {step === 0 && welcomeScreen}
      {step === 1 && nameSelector}
      {step === 2 && imageUpload}
    </div>
  );
}
