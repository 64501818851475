import { ChangeEventHandler } from 'react';
import { uploadImage } from '../lib/utils/imageUpload';
import { faCamera, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ImageUpload({
  onImageUpload,
  fileName,
}: {
  onImageUpload: (imageUrl: string) => void;
  fileName: string;
}) {
  const uploadIfdata: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = await uploadImage(file, fileName);
      onImageUpload(imageUrl);
    }
  };

  return (
    <input
      type="file"
      accept="image/*"
      capture
      onChange={uploadIfdata}
      style={{ display: 'none' }}
    />
  );
}
