import type { NextPage } from 'next';
import Head from 'next/head';
import React from 'react';
import { useRecoilState } from 'recoil';
import Dashboard from '../components/Dashboard';
import Signup from '../components/Signup';
import { userState } from '../lib/recoil/user';

const Home: NextPage = () => {
  const [user] = useRecoilState(userState);
  return (
    <div>
      <Head>
        <title>Pinne for ACO 2021</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
          rel="stylesheet"
        />
      </Head>

      <main className="flex items-center flex-col justify-center w-screen h-screen">
        {user ? <Dashboard /> : <Signup />}
      </main>
    </div>
  );
};

export default Home;
