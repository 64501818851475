import React, { useEffect, useState } from 'react';
import { useRive, EventType } from 'rive-react';
import Button from './Button';

type Props = {
  onAddBeer: () => void;
};

export default function AddBeer({ onAddBeer }: Props) {
  const [playing, setPlaying] = useState(false);
  const { RiveComponent, rive } = useRive({
    src: 'addbeer.riv',
  });

  useEffect(() => {
    if (rive) {
      // "play" event is fired when the animation starts to play
      rive.on(EventType.Play, () => {
        setPlaying(true);
      });

      // As above, the "pause" event is fired when the animation pauses.
      rive.on(EventType.Stop, () => {
        setTimeout(document.getElementById('animationButton')?.click());
      });
    }
  }, [rive]);

  function onButtonClick() {
    if (rive) {
      if (rive.isStopped) {
        rive.reset();
      } else {
        rive.play();
        onAddBeer();
      }
    }
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <RiveComponent />
      <Button id="animationButton" onclick={onButtonClick} disabled={playing}>
        Jeg har drukket noe!
      </Button>
    </div>
  );
}
