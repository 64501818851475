import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export const generateRandomFileName = () => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 12; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function uploadImage(file: File, fileName: string): Promise<string> {
  const storage = getStorage();
  const metadata = {
    contentType: 'image/jpeg',
  };
  const storageRef = ref(storage, fileName);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      reject,
      () => getDownloadURL(uploadTask.snapshot.ref).then(resolve)
    );
  });
}
