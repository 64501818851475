import { useRecoilState } from 'recoil';
import { userState } from '../lib/recoil/user';
import { faBeer, faUpload, IconDefinition, faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClock } from '../lib/hooks';
import Button, { ButtonColor, ButtonSize } from './Button';
import BeerStats from './BeerStats';
import Card from './Card';
import LastBeerStat from './LastBeerStat';
import { useRouter } from 'next/router';
import ImageUpload from './ImageUpload';
import { generateRandomFileName } from '../lib/utils/imageUpload';
import PickRandom from './PickRandom';

import { useState } from 'react';
import Modal from './Modal';
import AddBeer from './AddBeer';
import { useMutation } from '@apollo/client';
import { InsertBeerStat } from '../lib/graphql';
import UserLeaderboard from './UserLeaderboard';
import RoleLeaderboard from './RoleLeaderboard';

const DashboardButton = ({
  icon,
  title,
  color,
  onclick,
  size,
}: {
  icon: IconDefinition;
  title: string;
  color: ButtonColor;
  size: ButtonSize;
  onclick: () => void;
}) => {
  return (
    <div className="flex justify-center items-center flex-col">
      <Button buttonType="rounded" color={color} onclick={onclick} size={size}>
        <FontAwesomeIcon
          icon={icon}
          size={size === 'large' ? 'lg' : 'sm'}
          className={size === 'large' ? 'w-8 h-8' : 'w-4 h-4'}
        />
      </Button>
      <small>{title}</small>
    </div>
  );
};
function Clock() {
  const clock = useClock();
  return <h1 className="text-4xl">{clock}</h1>;
}

export default function Dashboard() {
  const router = useRouter();
  const [user] = useRecoilState(userState);
  const [addBeer, setAddBeer] = useState(false);
  const [insertBeerStat] = useMutation(InsertBeerStat);
  async function addBeerToUser() {
    await insertBeerStat({ variables: { user_id: user?.id } });
    setTimeout(() => setAddBeer(false), 500);
  }

  return (
    <div id="dashboard" className="p-4 w-full min-h-full ">
      <div className="flex justify-between items-center">
        {/* <h2 className="inline-block m-0">{user?.name}</h2> */}
        <img src="acoWhiteMindreWack.png" className="h-10" />

        <div className="flex justify-around items-center">
          {/* <div className="m-2 py-1 px-2 rounded-xl bg-primary  flex items-center justify-center">
            <span className="mr-1">2</span>
            <FontAwesomeIcon icon={faBeer} className="w-4 h-4" />
          </div> */}
          <img className="inline-block rounded-full w-10 h-10" src={user?.image_url || ''} />
        </div>
      </div>

      <div className="flex flex-col items-center justify-start p-4">
        {/* <img src="acoWhiteMindreWack.png" className="w-60" /> */}
        <h1>Halla {user?.name.split(' ')[0]}!</h1>
        <small className="text-gray-400">Klokka er</small>
        <Clock />
        <div className="flex justify-around w-full px-2">
          <div className="flex justify-center items-center flex-col">
            <label className="flex items-center justify-center rounded-full w-12 h-12 cursor-pointer my-3 bg-white text-black">
              <FontAwesomeIcon icon={faUpload} className="w-4 h-4" />
              <ImageUpload fileName={'gallery/' + generateRandomFileName()} onImageUpload={alert} />
            </label>
            <small>Ta bilde</small>
          </div>
          <DashboardButton
            size="large"
            icon={faBeer}
            title="Dranks"
            color="primary"
            onclick={() => setAddBeer(true)}
          />
          <DashboardButton
            size="default"
            icon={faImages}
            title="Galleri"
            color="outline"
            onclick={function (): void {
              router.replace('/gallery');
            }}
          />
        </div>

        <div className="flex flex-col items-center justify-center mt-10 w-full">
          <div className="w-full flex flex-col items-center justify-center">
            <h4>Stats</h4>
            <Card title="Forrige drank ble drukket av">
              <LastBeerStat />
            </Card>
            <Card title="Drikkeforbruk">
              <BeerStats />
            </Card>
            <Card title="Drikke-rulett">
              <PickRandom />
            </Card>
            <Card title="Rolletoppliste">
              <RoleLeaderboard />
            </Card>
            <Card title="Toppliste">
              <UserLeaderboard />
            </Card>
          </div>
        </div>
      </div>
      {addBeer && (
        <Modal>
          <AddBeer onAddBeer={addBeerToUser} />
        </Modal>
      )}
    </div>
  );
}
