import { useSubscription } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { LastBeerStatResponse, LastBeerStat as LastBeerStatQuery } from '../lib/graphql';
import { useDayjsClock } from '../lib/hooks';
import UserWithImage from './UserWithImage';

export default function LastBeerStat() {
  const { loading, error, data } = useSubscription<LastBeerStatResponse>(LastBeerStatQuery);
  const clock = useDayjsClock();

  const timeSinceLastDrank = useMemo(() => {
    const secondsAgo = clock.diff(data?.beer_stats[0].drank_at, 'seconds');
    if (secondsAgo >= 60) {
      const minutesAgo = Math.floor(secondsAgo / 60);
      return `for ${minutesAgo} ${minutesAgo === 1 ? 'minutt' : 'minutter'} siden`;
    }
    return 'nå nettopp';
  }, [clock, data?.beer_stats]);

  if (loading || !data?.beer_stats) return <p className="text-center">Laster..</p>;
  if (error) return <p className="text-center">`Error! ${error}`</p>;
  return <UserWithImage user={data.beer_stats[0].user} description={timeSinceLastDrank} />;
}
