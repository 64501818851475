import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { AllUsers } from '../lib/graphql';
import Button from './Button';
import UserWithImage from './UserWithImage';

const PickRandom = () => {
  const [randomDrinker, setRandomDrinker] = React.useState<{
    name: string;
    image_url: string;
  } | null>(null);
  const [timer, setTimer] = React.useState<number>();

  const { loading, error, data } = useQuery(AllUsers);

  useEffect(() => {
    if (timer === 0) {
      const random = data.users[Math.floor(Math.random() * data.users.length)];
      setRandomDrinker({ name: random.name, image_url: random.image_url });
    }
  }, [data?.users, timer]);

  if (loading) return <span>Loading...</span>;
  if (error) return <span>`Error! ${error.message}`</span>;

  const pickRandom = (data: any): void => {
    setTimer(3);
    setTimeout(() => setTimer(0), 3000);
    setTimeout(() => setTimer(1), 2000);
    setTimeout(() => setTimer(2), 1000);
  };

  return (
    <div className={'mx-auto flex items-center flex-col'}>
      <h2 className={'mx-auto text-center font-bold'}>
        {!!timer ? (
          <h1>{timer}</h1>
        ) : (
          randomDrinker && <UserWithImage user={randomDrinker} description="må drikke" />
        )}
      </h2>
      {!timer && <Button onclick={() => pickRandom(data)}>Hvem må drikke??</Button>}
    </div>
  );
};

export default PickRandom;
