import { gql } from '@apollo/client';

export const SignupMutation = gql`
  mutation Signup($image_url: String, $name: String, $role: String) {
    insert_users(objects: { image_url: $image_url, name: $name, role: $role }) {
      returning {
        id
        image_url
        name
        role
      }
    }
  }
`;

export const BeersConsumedEvery10min = gql`
  subscription BeersConsumedEvery10min {
    beers_consumed_every_10min(order_by: { start_time: asc }) {
      beers_consumed
      start_time
    }
  }
`;
export type BeersConsumedEntry = {
  beers_consumed: number;
  start_time: string;
};
export type BeersConsumedEvery10minResponse = {
  beers_consumed_every_10min: BeersConsumedEntry[];
};

export const LastBeerStat = gql`
  subscription LastBeerStat {
    beer_stats(limit: 1, order_by: { drank_at: desc }) {
      drank_at
      user {
        name
        image_url
      }
    }
  }
`;
export type LastBeerStatResponse = {
  beer_stats: {
    drank_at: string;
    user: {
      name: string;
      image_url: string;
    };
  }[];
};

export const InsertBeerStat = gql`
  mutation InsertBeerStat($user_id: Int) {
    insert_beer_stats_one(object: { user_id: $user_id }) {
      id
    }
  }
`;
export type InsertBeerStatResponse = {
  insert_beer_stats_one: {
    id: number;
  }[];
};

export const UserLeaderboard = gql`
  subscription UserLeaderboard {
    user_leaderboard {
      name
      beers_consumed
    }
  }
`;
export type UserLeaderboardResponse = {
  user_leaderboard: {
    name: string;
    beers_consumed: number;
  }[];
};
export const AllUsers = gql`
  query MyQuery {
    users {
      name
      image_url
    }
  }
`;

export const RoleLeaderBoardQuery = gql`
  subscription RoleLeaderboard {
    role_leaderboard {
      avg_per_user
      beers_consumed
      number_of_users
      role
    }
  }
`;

export type RoleLeaderBoardResponse = {
  role_leaderboard: {
    avg_per_user: number;
    beers_consumed: number;
    number_of_users: number;
    role: string;
  }[];
};
