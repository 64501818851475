import React from 'react';
import { View, Text } from 'react-native';

const UserWithImage = ({
  user,
  description,
}: {
  user: { image_url: string; name: string };
  description: string;
}) => {
  return (
    <h1 className="flex items-start flex-col ">
      <div className="flex items-center justify-center">
        <div
          className="w-20 h-20 bg-center bg-cover rounded-lg mr-4"
          style={{ backgroundImage: 'url("' + user.image_url + '")' }}
        />
        <div>
          <h1 className="text-red mb-0">{user.name}</h1>
          <h2 className="mb-0">{description}</h2>
        </div>
      </div>
    </h1>
  );
};

export default UserWithImage;
