import { useSubscription } from '@apollo/client';
import { faBeer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UserLeaderboard as UserLeaderboardQuery, UserLeaderboardResponse } from '../lib/graphql';
const UserLeaderboard = () => {
  const { data, loading, error } = useSubscription<UserLeaderboardResponse>(UserLeaderboardQuery);
  if (loading) return <p className="text-center">Laster..</p>;
  if (error) return <p className="text-center">`Error! ${error}`</p>;
  function getIndexColor(i: number) {
    switch (i) {
      case 0:
        return 'flex bg-yellow-400 w-10 h-10 mb-2';
      case 1:
        return 'flex bg-gray-200 w-8 h-8 text-black mb-2';
      case 2:
        return 'flex bg-yellow-800 mb-2';
      default:
        return 'inline-flex';
    }
  }
  return (
    <ul>
      {data?.user_leaderboard.map((user, i) => (
        <li
          className={`my-2 ${i < 3 ? 'flex flex-col items-center justify-center my-6' : ''}`}
          key={user.name + i}>
          <span
            className={`items-center justify-center w-6 h-6 rounded-full mr-2 ${getIndexColor(i)}`}>
            {i + 1}
          </span>
          <span>
            {user.name} ({user.beers_consumed} <FontAwesomeIcon icon={faBeer} />)
          </span>
        </li>
      ))}
    </ul>
  );
};

export default UserLeaderboard;
