import { ComponentProps } from 'react';

export type ButtonColor = 'primary' | 'white' | 'outline';
export type ButtonType = 'default' | 'rounded';
export type ButtonSize = 'default' | 'large';
export type Props = {
  buttonType?: ButtonType;
  size?: ButtonSize;
  color?: ButtonColor;
  onclick: () => void;
  children: JSX.Element | JSX.Element[] | string | String;
} & ComponentProps<'button'>;

export default function Button({
  color = 'primary',
  onclick,
  children,
  buttonType = 'default',
  size,
  ...rest
}: Props) {
  const className = () => {
    let tmpClass = 'my-3';

    switch (buttonType) {
      case 'rounded':
        tmpClass += ' flex items-center justify-center rounded-full ';
        tmpClass += size === 'large' ? 'w-20 h-20 ' : 'w-12 h-12';
        break;
      case 'default':
        tmpClass += ' rounded-lg px-5 py-3';
        break;
    }

    switch (color) {
      case 'primary':
        return tmpClass + ' bg-primary';
      case 'outline':
        return tmpClass + ' border border-gray-600';
      case 'white':
        return tmpClass + ' bg-white text-black';
    }
  };
  return (
    <button id={rest.id} className={className()} onClick={onclick} disabled={rest.disabled}>
      {children}
    </button>
  );
}
